.headerFormat {
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    padding: 10px;
}

.formContainer {
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    display: block;
    padding-top: 10px;
}

.customInputGroup {
    margin-bottom: 1px;
    display: block;
    justify-content: center;
}

.customInputGroup .form-control {
    width: 100%;
    max-width: 300px;
}

.payloadTextArea {
    height: 150px;
    font-size: 14px;
    width: 180%;
    max-width: 500px;
}

.submitButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 10px
}

.addPadding {
    padding-top: 10px;
}

.resultDisplay {
    border: 1px solid ;
    padding-top: 10px;
}
