.drawBox {
    border:        2px solid black;
    padding:       20px;
}

.centeredOrStatement{
    font-weight: bold;
    margin-bottom: 0px!important;
    text-align: center!important;
    display: block;
}

.addPadding {
    padding-top: 10px;
}